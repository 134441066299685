import { CCApiService } from './api-service';
import { UIConfig } from './config-service';
import { ServiceAccount, ServiceAccountForm } from '../../components/service-accounts-table-card/interfaces';

export class ServiceAccountsService {
  static ApiService: CCApiService;
  static serviceAccountsAPI: string;
  static introspectionAPI: string;
  static apiBasePath: string;

  static init(config: UIConfig) {
    this.ApiService = new CCApiService(config.authClientId, config.authIssuerUri);
    this.apiBasePath = config.apiBasePath;
    this.introspectionAPI = `${this.apiBasePath}/introspection`;
  }

  static async getServiceAccount(customerId, id): Promise<ServiceAccount> {
    this.serviceAccountsAPI = `${this.apiBasePath}/customers/${customerId}/serviceaccounts`;

    return this.ApiService.performFetch(`${this.serviceAccountsAPI}/${id}`).then(entities => {
      return entities;
    });
  }

  static async getServiceAccounts(customerId, filter: {}): Promise<any> {
    this.serviceAccountsAPI = `${this.apiBasePath}/customers/${customerId}/serviceaccounts`;

    return this.ApiService.performFetch(`${this.serviceAccountsAPI}?${new URLSearchParams(filter)}`).then(entities => {
      return entities;
    });
  }

  static async getServiceAccountCredentials(customerId, id): Promise<{clientId: string, clientSecret: string}> {
    this.serviceAccountsAPI = `${this.apiBasePath}/customers/${customerId}/serviceaccounts/${id}/credentials`;

    return this.ApiService.performFetch(`${this.serviceAccountsAPI}`).then(entities => {
      return entities;
    });
  }

  static async createServiceAccount(customerId, serviceAccount: ServiceAccountForm): Promise<ServiceAccount> {
    const fetchOptions = {
      method: 'POST',
      body: JSON.stringify(serviceAccount),
    };
    this.serviceAccountsAPI = `${this.apiBasePath}/customers/${customerId}/serviceaccounts`;

    return this.ApiService.performFetch(this.serviceAccountsAPI, fetchOptions).then(entity => {
      return entity;
    });
  }

  static async updateServiceAccount(customerId, serviceAccount: ServiceAccountForm): Promise<ServiceAccount> {
    const fetchOptions = {
      method: 'PUT',
      body: JSON.stringify(serviceAccount),
    };
    this.serviceAccountsAPI = `${this.apiBasePath}/customers/${customerId}/serviceaccounts`;
    return this.ApiService.performFetch(`${this.serviceAccountsAPI}/${serviceAccount.id}`, fetchOptions).then(entity => {
      return entity;
    });
  }

  static async deleteServiceAccount(customerId, id: string): Promise<{}> {
    const fetchOptions = {
      method: 'DELETE',
    };
    this.serviceAccountsAPI = `${this.apiBasePath}/customers/${customerId}/serviceaccounts`;
    return this.ApiService.performFetch(`${this.serviceAccountsAPI}/${id}`, fetchOptions).then(entity => {
      return entity;
    });
  }

  static async getScopes(): Promise<{scopes: [{name: string, description: string}]}> {
    return this.ApiService.performFetch(`${this.introspectionAPI}/scopes`).then(entities => {
      return entities;
    });
  }
}